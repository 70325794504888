import React, { useEffect, useRef } from "react";
import 'photoswipe/dist/photoswipe.css'

import { Gallery, Item } from 'react-photoswipe-gallery'


import {Photo} from "./photoContainer";

type Props = {
    images: Photo[];
    setImages: React.Dispatch<React.SetStateAction<Photo[]>>;
};


const ImageGallery = ({ images, setImages }: Props) => {
    return (
         <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
             <Gallery withDownloadButton={true}>
                 {images.map((item, index) => (
                     <Item
                         key={index}
                         original={item.src}
                         width={item.width}
                         height={item.height}
                     >
                         {({ ref, open }) => (
                             <img
                                 ref={ref}
                                 onClick={open}
                                 src={item.src}
                                 alt=""
                                 className="rounded-lg cursor-pointer w-full h-40 object-cover"
                             />
                         )}
                     </Item>
                 ))}
             </Gallery>
          </div>

    );
};

export default ImageGallery;
