import React, {useCallback, useEffect, useState} from 'react';
import ImageGallery from './ImageGallery';
import { collection, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {Button} from "@chakra-ui/react";
import axios from 'axios';


export type Photo = {
    src: string;
    width: number;
    height: number;
    user: string;
    id: string;
    url: string;
};


const PhotoContainer = () => {
    const [photosF, setPhotos] = useState<Photo[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchPhotos = useCallback(async () => {
        setIsLoading(true);
        const db = getFirestore();
        const photoCollection = collection(db, "images");
        const photoSnapshot = await getDocs(photoCollection);
        const photoList = photoSnapshot.docs.map(doc => {
            try {
                const data = doc.data();
                return {
                    src: data.url,
                    width: data.width,
                    height: data.height,
                    user: data.name,
                    id: data.id,
                    url: data.url
                };
            } catch (error) {
                console.error('Error al procesar la foto:', error);
                console.error('URL de la foto:', doc.data().url);
                console.error('Datos de la foto:', doc.data());
            }
        });
        const validPhotos = photoList.filter(photo => photo !== undefined) as Photo[];
        setPhotos(validPhotos);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchPhotos().then();
    }, [fetchPhotos]);



    const downloadAllImages = async () => {
        const zip = new JSZip();
        const imageFolder = zip.folder("images");

        await Promise.all(photosF.map(async (photo, index) => {
            const response = await axios.get(photo.src, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'image/jpeg' });
            imageFolder?.file(`image${index + 1}.jpg`, blob);
        }));

        const content = await zip.generateAsync({type:"blob"});
        saveAs(content, "images.zip");
    };


    return (
        <div className="container mx-auto mt-4 ">
            {isLoading ? (
                <div>Cargando tus fotos...</div>
            ) : (
                photosF.length > 0 && (
                    <>
                       {/* <div className={'flex justify-end mb-3'}>
                            <Button onClick={downloadAllImages} colorScheme='teal'>Descargar todas las imágenes</Button>
                        </div>*/}
                        <ImageGallery images={photosF} setImages={setPhotos} />
                    </>
                )
    )
}
</div>
)
    ;
};

export default PhotoContainer;
