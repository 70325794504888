
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyCX0whEwWWf5ywVXYVxGYPZJ-c9bxbfKm0",
    authDomain: "proyecto-fotos-4a305.firebaseapp.com",
    databaseURL: "https://proyecto-fotos-4a305-default-rtdb.firebaseio.com",
    projectId: "proyecto-fotos-4a305",
    storageBucket: "proyecto-fotos-4a305.appspot.com",
    messagingSenderId: "1066480905158",
    appId: "1:1066480905158:web:8ee21cc465e3b8332b20c7",
    measurementId: "G-83CB9QL39L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
