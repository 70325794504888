import React, {useEffect} from 'react';
import Container from "./container";
import { auth } from '../../firebaseConfig';
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const history = useNavigate();
    const handleLogout = (e: any) => {
        e.preventDefault();
        auth.signOut().then(() => {
            history('/')
        })
    }



    return (
        <>
            <header className="bg-blue-600 p-4 shadow-md">
                <div className="container mx-auto flex justify-between items-center">
                    <h1 className="text-white text-2xl font-semibold">Mi Aplicación</h1>
                    <button
                        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
                        onClick={handleLogout}
                    >
                        Salir
                    </button>
                </div>
            </header>
            <div >
                <div>
                    <Container />
                </div>
            </div>
        </>


    );
};

export default Dashboard;
