import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {Trash} from "iconsax-react";
import {getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { collection, setDoc, doc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { Spinner } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'

const Card = () => {
    const [files, setFiles] = useState<{ file: File; id: string }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [userName, setUserName] = useState("");
    const toast = useToast()
    const storage = getStorage();


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: File[]) => {
            setFiles(prevFiles =>
                prevFiles.concat(
                    acceptedFiles.map(file => ({ file, id: URL.createObjectURL(file) }))
                )
            );
        },
        accept: {
            "image/jpg": [".jpg"],
            "image/jpeg": [".jpeg"],
            "image/png": [".png"],
            "image/heic": [".heic"],
        }
    });

    const handleUpload = async () => {
        setIsLoading(true);
        setIsUploadSuccessful(false);

        const db = getFirestore();

        for (let i = 0; i < files.length; i++) {
            const { file } = files[i];

            const timestamp = Date.now();
            const originalFileName = file.name.split('.').slice(0, -1).join('.');
            const fileExtension = file.name.split('.').pop();
            const user = userName || "anonimo";
            const newFileName = `${user}_${originalFileName}_${timestamp}.${fileExtension}`;
            const storageRef = ref(storage, 'images/' + newFileName);
            const uploadTask = uploadBytesResumable(storageRef, file);

            if (!file.type.startsWith('image/')) {
                console.log('File is not an image:', file);
                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);
            await img.decode();
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress((i + progress / 100) / files.length * 100);
                },
                (error) => {
                    console.log(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(storageRef);
                    const docRef = doc(collection(db, "images"));
                    await setDoc(docRef, {
                        id: docRef.id,
                        name: user,
                        url: downloadURL,
                        ext: fileExtension,
                        width: width, // Agregar el ancho de la imagen
                        height: height, // Agregar la altura de la imagen
                    });
                }
            );
        }

        toast({
            colorScheme: 'purple',
            title: '¡Gracias!.',
            description: "Tus fotos se subieron correctamente y las agregaremos a nuestro album, nuevamente muchas gracias por ser parte de nuestro dia!",
            status: 'success',
            duration: 7000,
            variant: 'top-accent',
            isClosable: true,
        })
        setIsUploadSuccessful(true);
        setFiles([]);
        setIsLoading(false);
    };

    return (
        <div
            className="flex justify-center items-center min-h-screen px-3"
            style={{backgroundImage: `url(/assets/bg.webp)`, backgroundSize: 'cover', backgroundRepeat: 'repeat'}}>
            <div>

            <div>
                <div className="text-5xl font-semibold text-teal-950 mb-4 dancing-font text-center bg-white bg-opacity-50"><div>
                    Anderson y Angela
                </div>
                <div className={'text-2xl'}>Nuestra boda...♥</div></div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-semibold text-gray-700 mb-4 protest-riot-regular">Comparte tus fotos del
                    evento </h2>

                <div className="mb-4">
                    <label htmlFor="text" className="block text-gray-600 font-medium protest-riot-regular">
                        Tu nombre
                    </label>
                    <input
                        type="text"
                        id="text"
                        name="text"
                        className="w-full border rounded-lg py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-teal-400 protest-riot-regular"
                        placeholder="Pon tu nombre aqui..."
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <div
                        {...getRootProps()}
                        className="border-2 border-teal-600 border-dashed rounded-lg py-4 px-4 mt-2 focus:outline-none focus:ring focus:border-teal-900 cursor-pointer text-center"
                    >
                        <input {...getInputProps()}  />
                        <p className={'protest-riot-regular'}>Haz clic aqui para seleccionar las fotos</p>
                    </div>
                    {files.length > 0 && (
                        <div className="mt-4 grid grid-cols-3 gap-4">
                            {files.map(({file, id}) => (
                                <div key={id} className="relative group">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`Imagen ${id}`}
                                        className="max-w-full max-h-32 mx-auto"
                                    />
                                    <Trash onClick={() => {
                                        setFiles(prevFiles => prevFiles.filter(f => f.id !== id));
                                    }} size="32" color="red" variant="Bold"
                                           className="absolute top-0 right-0 invisible group-hover:visible hover:text-red-600 text-white font-semibold py-1 px-2 rounded-full cursor-pointer"/>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {isLoading &&
                    <div className="relative pt-1">
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                            <div style={{width: `${uploadProgress}%`}}
                                 className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-fuchsia-500"></div>
                        </div>
                    </div>
                }
                <button onClick={handleUpload}
                        disabled={isLoading || files.length === 0}
                        className="mt-4 bg-teal-500 text-white py-2 px-4 rounded w-full protest-riot-regular">
                    {isLoading ? <Spinner /> : 'Enviar'}
                </button>
            </div>

            </div>
        </div>
    );
};

export default Card;
