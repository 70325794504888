import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="text-center">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">Bienvenido a Recuerdos Compartidos</h1>
                <p className="text-lg text-gray-600 mb-8">¡Tus invitados podran compartir fotos de tu evento contigo!</p>
                <Link to="/upload">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300">
                        Ir a subir fotos
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Home;
