import React, {useState} from 'react';
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth'
import {useNavigate} from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useNavigate();
    const handleLogin = (e: any) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password).then(data => {
          history('/dashboard')
        })

    }
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Iniciar sesión</h2>
                <form >
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-600 font-medium">Correo electrónico</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="w-full border rounded-lg py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-400"
                            placeholder="Tu correo electrónico"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-600 font-medium">Contraseña</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="w-full border rounded-lg py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-400"
                            placeholder="Tu contraseña"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        onClick={handleLogin}
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
                    >
                        Iniciar sesión
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
