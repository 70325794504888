import React from 'react';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/normal/Home';
import Login from './pages/normal/login';
import {ChakraProvider} from "@chakra-ui/react";
import Dashboard from "./pages/admin/dashboard";
import PrivateRoutes from "./layouts/PrivateRoutes";
import Card from "./pages/normal/upload";

function App() {
  return (
      <ChakraProvider>
          <Router>
             <Routes>
                <Route path="/login" element={ <Login />} />
                <Route path="/" element={ <Home />} />
                 <Route path="/upload" element={ <Card />} />
                 <Route element={<PrivateRoutes />}>
                <Route path={"/dashboard"} element={ <Dashboard />} />
                 </Route>
            </Routes>
          </Router>
      </ChakraProvider>
  );
}

export default App;
